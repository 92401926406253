import { useNavigate } from 'react-router-dom';
import useScrollAnimation from '../../hooks/useScrollAnimation';

import newsImg1 from '../../utils/images/blogImg1.jpeg';
import newsImg2 from '../../utils/images/blogImg2.jpeg';
import newsImg3 from '../../utils/images/blogImg3.jpeg';
import newsImg4 from '../../utils/images/blogImg4.jpeg';
import newsImg5 from '../../utils/images/blogImg5.jpeg';
import newsImg6 from '../../utils/images/blogImg6.jpeg';
import newsImg7 from '../../utils/images/blogImg7.jpeg';

import './blog.css';
const blogNewsDetails = [
    { title: 'How Dangote Builds Empires in a Recession', date: 'August 30, 2023', displayImg: newsImg1, 
      paragraph1: "Since it's been a while since I heard from my rich uncles, I decided to check up on them personally. Apart from wanting to know their well-being, I also wanted to find out how they are keeping up with this economy. What I found out was shocking. Despite the scorching inflation, devaluation, and economic inconsistencies, these three Nigerians seem to be living in a different world. Starting from the oil and telecommunications mogul whose $5.8 billion wealth is undisputable, as described by Forbes; Mike Adenuga (GCON) recently crossed another milestone as his foremost telecommunication company- Glo recently marked her 19-year anniversary and won the most popular telecommunications brand of the year in the country. In addition to that, his oil company- Conoil Plc despite a decline in revenue and a hike in operating expenses, Conoil Plc reported a double-digit increase in profits to begin the 2022 financial year on an impressive note.</p>", 
      paragraph2: "Moving on to Abdul Samad Rabiu (CEO, BUA Group), the silent cement billionaire whose $6.5 Billion net worth cannot be concealed. In discharging its Corporate Social Responsibility (CSR), the BUA Cement Company has supported some host communities in Sokoto with N360 million worth of projects and payment of scholarships to indigent students. In fact, if not for the unfit topography, BUA Cement should have recently acquired 50,000 hectares of land from the Kogi state government for investment purposes. That's a piece of separate news from their projected N102.68 billion revenue in Q3 2022. Now to the biggest masquerade that wraps up the ritual, the $12.1 Billion Africa's Richest Man and Chairman of Dangote Group- Aliko Dangote. Most significant is the nearly completed 650000bpd refinery projected to commence operation by mid-2023. There's only one lesson to be learned here. While the majority focus on the upward or downward economic chats and blame everything and everyone there is to blame, with fear, panic, and anxiety getting the best of them, these three people and many others have learned to MIND THEIR BUSINESS, come rain come shine.</p>", 
      paragraph3: `Instead of the incessant complaints about the unfavorable economy, why not seek opportunities to grow your business? These people have learned to focus on what they can control and leave the rest. Instead of blaming the government for your business failures, can you take responsibility to seek knowledge and mentorship? If you learn to start minding your business, your name is next on Forbes. If you want to know how to "Retire Early" with the Simplest Hands-On Strategy for Financial Clarity, Goal-Setting, and Growth-Tracking, specially designed by me; click here (https://wa.me/2348120777034) to send me a DM with "Guide Me" on WhatsApp.</p>`
    },
    { title: 'How To Deal With Bad Debt', date: 'August 30, 2023', displayImg: newsImg2, 
      paragraph1: 'This is an honest story.', 
      paragraph2: "I have journeyed from being a National TV Video Editor, Newspaper and Magazine Editor and Publisher, Research Data Analyst, Graphics Designer or Art Director, Copywriter, Web Designer, Business Plan & Feasibility Study Writer, Brand Strategist, Social Media Manager, Accounts Manager, Digital Media & Branch Communications Lead, Finance Writer and now to a Real Estate Broker. Hence, when it comes to being skilled, I was sometimes too skilled to focus. Also, my undying entrepreneurial spirit led me to launch a couple of businesses ranging from clothing retail, food-based e-commerce, digital agency, and recruit-tech. I made some money, but I also got into debt with a business deal that sent me packing. ", 
      paragraph3: "I believe you now see the full picture. The question is, how did I change all that? One, I picked one thing to settle with. I was aware of my sales skills. I had won 3 national pitching competitions, campus presidency, and the hearts of many clients and employers. In fact, I used to boast that I have never been rejected after a job interview. So, I decided to go into sales. Two, I found something to sell. For some people, their number one will be to focus on what they know how to do that they can sell. That can be fashion designing, content writing, videography, etc. For me, I had to find something to channel my sales skills towards. Then, I found real estate. Three, I went in one hundred percent. From day one, I started making videos, posting on all my social media platforms, telling anyone who cares to listen, sharing broadcast messages, etc. For some, it might be getting a job and giving that job a hundred percent.", 
      paragraph4: "Lastly, for me to have the necessary push, I had to:", 
      paragraph5: `<ol><li>Set a clear financial goal</li><li>Express my current financial state on paper, stating my income, expenses, etc.</li><li>Define my income streams and strategies to make them happen.</li></ol>`, 
      paragraph6: `Hence, I knew what I was up against. I wrote down "I must make X amount, by x/xx/xxxx, by doing so and so, in other for me to get so and so. So help me God." <br /> Then, I had a clear goal. I was no longer focused on or burdened by my debt. `,   
      paragraph7: `<ol><li>I had a deadline, so I had an internal drive every day.</li><li>I had a clear-cut strategy. I wasn't confused, and my focus was on one thing.</li><li>Lastly, I had my desires listed out: what I wanted to do with the financial goals.</li></ol>`, 
      paragraph8: "This simple strategy is what I still do today. It doesn't matter how much I need, I ask myself one question- what do I have that people will definitely pay me for? Once I can answer that, the next thing is to show up daily pushing that offer. If you are still confused after reading this post, TEXT ME 'HELP' and I'll be glad to personally help you.", 
    },
    { title: 'No.1 Secret to Profit from Recession', date: 'August 30, 2023', displayImg: newsImg3, 
      paragraph1: `Factually, times are hard. Amidst global commodity shocks, a depreciating currency, trade restrictions, and monetization of the deficit, inflation is surging and pushing millions of Nigerians into poverty (World Bank). On a global scale, the U.S. has already experienced two consecutive quarters of negative GDP growth in 2022, which some people consider to be a recession. The OECD Economic Outlook reports that "the world economy is slowing more than anticipated". Everyone has a provable reason to panic, be anxious, lament, and blame the government, spouses, parents, or whoever is in a blameable position. I mean, inflation is predicted to be worse in developing economies, where price increases are projected to reach 9.9 percent on average over the course of this year. In particular, Nigeria's inflation currently sits at an alarming 21.09%. Hence, you can't blame the sad tweets, attempted suicides, job layoffs, etc <br />The question is, is there anything good in all these sad reports? Well, let's read the following highlights from Investopedia together:`, 
      paragraph2: `<ol><li>The 1981-1982 recession helped to lower the inflation rate from 11% in June 1979 to 5% by October 1982, and the U.S. economy grew for the next eight years.</li><li>A recession can end the misallocation of investment capital; setting the stage for an eventual recovery and renewing the foundations for economic growth.</li><li>Higher interest rates during inflation can translate to higher yields on deposits in savings accounts.</li><li>A recession can be a good time to hunt for bargains and purchase undervalued assets.</li></ol>`, 
      paragraph4: `However unsettling it may be to see all that red ink on your account statement, it's important to avoid overreacting and selling your assets at their recession-induced lows. The first thing to know is that recessions are an inevitable and necessary part of the economic cycle. Hence, this knowledge will keep your eyes open and your mind alert. Like I always say, the major thing to guard jealously in a tough economy is YOUR CONTROL! You must learn to be in charge of your financial decisions. They must not be fueled by panic or fear. Rather, strategic calculations. This may not always be favorable, but making the decisions boldly, enables you to pick yourself up and try again quickly. 
                  The biggest enemy in a tough economy is not the sad statistics. It's the fear and panic that erodes people's mind and lead them to make poor financial decisions. One thing wealthy people have learned to do in this recession that has consistently favored them is BUYING CHEAP INVESTMENTS. During a recession, there is more supply for investments than demand for them. There are more people willing to sell their investments than there are those willing to buy them. The media plays a vital role in spreading fear and terror. Prices of stock, real estate, and other investments drop.`, 
      paragraph3: `Hence, this is the time to start acquiring those undervalued properties and hold them till the economy recovers. It is the fear of buying when it could still go down that holds people from investing during a recession. Smart investors, however, invest for the long term. They understand that what matters is that you buy at cheap prices and then hold. They may lose further down the line, but once things start recovering, they will be in a better position for profit. If you'd love to engage yourself in such and need a financial advisor to guide you with insights and strategic recommendations, chat me up. In conclusion, the only people who ever thrive in a tough economy are those who see value where others see the only price; those who analyze data where others read only headlines; those who see opportunity where others see only disaster; those who follow their instincts where others follow the crowd; those who think long term where others think short term; those who have the courage where others have only fear; and those who are patient when others are anxious. Those are the people who become rich during a recession. The question is are you one of them?
                  <br />If you have any questions please let us know by contacting us at +234 812 077 7034
                  <br />Thank you.`, 
    },
    { title: 'Are you skilled for investing?', date: 'August 30, 2023', displayImg: newsImg4, 
      paragraph1: `"Investing" in itself is vague. Everyone invests in something- alcohol, drugs, education, social media, career, etc. Hence, investing in something does not automatically make you financially smart. If someone walks up to me and says "I just bought x stock", or "I just got a plot of land in x location" or "I just got a building in x place". Before I say congratulations, I'd first ask WHY? The difference between a smart and a foolish investment is the WHY. This brings me to my first rule of Investing- Have a WHY! Clear as day.`, 
      paragraph2: `A young man walked into my office today and asked me, "what do you think I can start investing in?" and I replied, "Why do you want to invest?" I could see the shock on his face. Well, he was smart enough to say "I just made some money from a gig. I'm looking for something strategic to invest in that can give me some monthly cashflow or a great ROI in the next 6 months." I was so glad to hear his response that I offered him a seat. Once you have a clear "WHY", it is easy to know where to look. Else, you will be like a doctor trying to treat a patient without a diagnosis. Every drug looks right. 
                  <br />Many people have been wrecked by investing in stocks, crypto, and even real estate without a WHY. Someone hits a sudden 7-figure and the next thing is to find a trending crypto to buy- that's dead on arrival. Having a WHY makes you investigate whatever you want to invest in to know if it aligns with your financial goal. This brings me to the next point- Invest in the knowledge of a business, before investing in the business. You have a financial goal you are aiming at. You want to know why the property is the right one for you. What's the cash flow potential of the property? Does it align with your expected income? What's the capital gain potential of the property? Does it align with your expected ROI? What's the yield timeline (monthly/annual)? Does it align with your expected inflow? What are the factors that point to the potential of a property?`, 
      paragraph3: `These are not questions for your broker but rather answers that a broker is meant to provide you before you decide to invest in any property. In fact, one of my mentors will say that if you cannot explain your investment decisions to a 7-year-old, you should not invest yet. When you prioritize having a clear WHY, and the patience to understand the investment choices available and align them with your financial goal, you will start making flawless (not risk-free) investment decisions. I said "flawless" because whatever happens along the line, you will be able to come to terms with it without feeling lost. Beyond the mathematics of investing, psychology is more important. 
                  <br />If you have any questions please let us know by contacting us at +234 812 077 7034
                  <br />Thank you.`, 
    },
    { title: 'The 1% vs The Average Nigerian', date: 'August 30, 2023', displayImg: newsImg5, 
      paragraph1: `An average Nigerian in Nigeria has a toxic relationship with money. Such relationships are filled with fear, insecurity, anxiety, aggression, etc. An average salary earner happily expects the salary by month's end but is soon down with anxiety three days later. An average undergraduate has a sequential standard of living every session- rice, and chicken to noodles and eggs to garri and groundnuts. An average couple has a monthly quarrel when it comes to apportioning money to needs in the family. An average person on the street can strip themself of all decency to claim a ten naira change from a bus conductor. An average civil servant is constantly seeking ways to extort anyone who needs any registration or renewal. 
                  <br />The saddest part of this is the one thing they all have in common. They are all average. Being average means being of the usual, common, or ordinary standard, level, or rate. However, it takes an unusual or uncommon, or extraordinary level of thinking, habits, or culture to build wealth. Hence, a sign that anyone is average is that you behave in the common or usual way that most people do.`, 
      paragraph2: `It is common to borrow money to buy Aso-Ebi, an iPhone you don't need, a concert ticket to prove a point, food or clothing outside of your budget or just to hide that you are truly broke. Interestingly, in all the success stories I've read, watched, and listen to, the turning point of all was the day they acknowledged their financial insufficiencies without being ashamed of people's opinions or verdicts. Rather, confronting it squarely. It is common not to have a savings or investment budget. It is common not to give because he or she doesn't have enough. It is common not to take any course or read any book on financial literacy or wealth building. It is common to think all rich people are corrupt. It is common to think that one politician is going to make one rich overnight. It is common to think that one anointing oil will suddenly make one wealthy without corresponding wisdom. It is common to be poor! It takes an intentional effort to embrace the uncommon. It takes discipline, a painful one. It takes humility to learn. It takes commitment to set financial goals, to live on a budget, to save, to learn how to invest and what to invest in, and to intentionally build a network of financially free people. Two poor people cannot help each other. Some people need to open up about their financial status to the right people. Some need to find the right mentor. Some need to leave their current environment and find an enriching environment.       
                  <br />Some of the wealthy habits that aren't common are:`, 
      paragraph3: `<ol><li>Constant learning (books, courses, mentorship, etc.)</li><li>Discipline with Time (It is common to be late. But not with wealthy people.)</li><li>Anti-Waste Personality</li><li>Honesty and Loyalty (Build Trust and Social Credibility with People)</li><li>Think Long-Term (Stop thinking now. Stop thinking instant gratification. Think long-term gratification.)</li></ol>`, 
      paragraph4: `Wealth is not common. That's why 2% of Nigerians have 90% of all Bank Deposits. Hence, if you want to be financially free, start doing the uncommon. A community you can consider is our Investors community. A society of high-net-worth individuals with access to mentorship, resources, and investment opportunities to set you on a journey to financial freedom.
                  <br />Interested? Comment "Investors" and I'll be in your DM. Or you can call me on +2348120777034 for more information.`
    },
    { title: 'Upper Class vs Others', date: 'August 31, 2023', displayImg: newsImg6, 
      paragraph1: "With a deep dive into the ongoing financial bloodbath of the Nigerian WAR-king class, this mail might ruin your TGIF mood, but not if you read it to the end.", 
      paragraph2: `Firstly, here are some cold hard facts: <br />
                  <ol><li>BusinessDay reports that only 7.19% of the working class earns above N3 Million annually or N250,000 monthly.</li><li>Nigeria's Food inflation has increased by 15% in the last year, according to The Conversation US.</li><li>Even if an average working class person was to acquire a property in Ikorodu, Ebute Metta, both in the mainland axis of Lagos, or Choba in Port Harcourt, which have similarities with Wuse in Abuja, he or she would have to save for at least 20 years (Zeal Akaraiwe tells BusinessDay)</li></ol>`, 
      paragraph3: `I'm not writing this to spoil your "Thank God It's Friday" mood. Rather, to help map out an informed escape out of this financial abattoir. I believe everyone's level of wealth is a result of their mindset and the opportunities they harnessed. These duos are products of education, environment, exposure, network, etc., and we all have them in varying proportions. The following highlights are what I learned from the Undercover Billionaire Documentary featuring Grant Cardone, Glenn Stearns, Elaine Culotti, and Monique Idlett-Mosley. These four individuals were given $100 in a community they have never been to and asked to build a $1,000,000 business in 90 Days.
                  <br /> PS: Don't read further if you believe your case is peculiar and all these abroad strategies cannot work. Who am I to convince you otherwise?`, 
      paragraph4: `Here are the top 3 things to start doing: <br />
                  <ol><li>Set Financial Goals.</li><li> Shift from Thinking Consumption to "Creation".</li><li>Ignore every negative news, factor, or person.</li></ol>`, 
      paragraph5: `Setting financial goals will first make it clear to you that your salary is your least stream of income. Especially for those in jobs they are unhappy with and have no future in. Suddenly, your brain is open to thinking about achieving that figure within the set time. You stop blaming your boss, the government, or your family and start to take responsibility for your own financial freedom. How about making 1 Million in 60 Days? That will give you 6 Million in one year, you are already among the top 8% of the Nigerian Middle class, on your way to the upper class. The next question is- How? What do I sell? What do I know how to do? What do I need to learn? Whom do I need to talk to? I believe every struggling stage is a transition phase. You are not meant to stay there forever. But that is if you truly want something more and are ready to get it at all costs.
                  <br />What's your financial goal? I'd love you to reply to this mail if you don't mind. I'll be chatting some people up privately.`,
    },
    { title: 'What’s up with Crypto?', date: 'August 31, 2023', displayImg: newsImg7, 
      paragraph1: "Since the first decentralized cryptocurrency - Bitcoin- debuted in 2009, it's been a wave of different news and emotions among investors, traders, and gossip. 9,000 cryptocurrencies and over $1 Billion market capitalization later, there are still questions about its potential as a bankable investment opportunity. Let's take an objective view of this subject matter.", 
      paragraph2: `Bitcoin- The no. 1 Cryptocurrency- grew from a value of $0.0009 in 2010 to $7,200 (2020). That's a decade growth of 9,023.01%. Isn't that a great return on Investment when compared to the best-performing stock of the same decade- Netflix with a return of 4,011%? As good as that sounds, return/capital growth is only one of the three most important investment factors to consider (Investopedia). The other two critical factors are "Safety" and "Income/Cashflow". Though there is no such thing as a completely safe and secure investment, there are different levels of risk attached to investment offers namely; low-risk, medium-risk, and high-risk investments.`, 
      paragraph3: `Hence, it's important that investors balance their risk appetite with their investment choices. Cryptocurrency is a high-risk investment. Hence, don't blame the bearish move having enjoyed all that the bullish adventure offered. History makes the best forecast. This is why real estate has been and will always be the leveling ground for investors' safety, cash flow, and capital growth. 
                  <br />Thinking about investing in viable real estate? call or text me at +2348120777034 or click here to chat with me.`, 
    },
    // { title: '', date: '', displayImg: newsImg1, 
    //   paragraph1: "", 
    //   paragraph2: "", 
    //   paragraph3: "", 
    // },
];

const Blog = () => {
  useScrollAnimation();
  const navigate = useNavigate();

  const goToBlogDetails = (details) => {
    navigate('/blog_details', { state: { details } })
  }

  return (
    <main className='page-width blog-section container-fluid my-5 pb-5'>

      <h1 className='mb-4 pt-4'>Recent news</h1>

      <section className="row blog-news my-5 js-scroll fade-in">
        {blogNewsDetails.map((detail, index) => (
          <div role='button' onClick={() => goToBlogDetails(detail)} key={index} className="col-md-4 col-sm-12 my-md-5 my-2">
            <div className="imgHover_effect news-item">
              <img src={detail.displayImg} alt="newsImg" className="img-fluid" width='100%' />
              <div className="overlay"></div>
              <div className="news-details">
                <h4 className="custom-besleyFont mb-0">{detail.title}</h4>
                <p className='custom-neueFont_roman'>{detail.date}</p>
              </div>
            </div>
          </div>
        ))}
      </section>

    </main>
  )
}

export default Blog