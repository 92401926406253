import { useEffect } from "react";

const Client = () => {

    const handleNavigate = () => {
        window.location.href = 'https://selar.co/m/adesinaco';  // Navigates to a new URL
    };

    useEffect(() => {
     handleNavigate();
    }, [])


    return ( <>
      <span></span>
    </> );
}
 
export default Client;